<template>
    <modal ref="modalRegistrarPago" titulo="Registrar Pago" no-aceptar icon="aprobar" adicional="Pagar" tamano="modal-lg" @adicional="pagar()">
        <div class="row mx-0 justify-center">
            <div class="col-10">
                <p class="text-general f-14 pl-3">Valor a pagar</p>
                <input-miles v-model="valorPagar" class="w-100" :money-options="money" />
            </div>
            <div class="col-10 mt-3">
                <p class="text-general f-14 pl-3">Fecha de Pago</p>
                <el-date-picker
                v-model="fecha"
                type="date"
                placeholder="Fecha de pago"
                />
            </div>
            <div class="col-10 text-general f-15 mt-3">
                <p class="text-general f-14 pl-3">Comentario</p>
                <el-input v-model="comentarioPago" type="textarea" maxlength="300" rows="3" />
            </div>
            <div class="col-10">
                <div class="mt-2">
                    <slim-cropper ref="fotoTransferencia" :options="slimOptions" class="border cr-pointer" style="height:250px;width:220px;background-color:#F5F5F5;border-radius:6px;" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import pagos from '~/services/pagos/pagos'
export default {
    data(){
        return{
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            valorPagar: '',
            fecha: '',
            comentarioPago: '',
            valorMaximo: 0,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '$ ',
                precision: 2,
                masked: false
            },
            datos: {}
        }
    },
    methods: {
        toggle(datos){
            this.datos = _.cloneDeep(datos)
            this.valorMaximo = this.datos.total_debe
            this.valorPagar = this.datos.total_debe
            this.money.precision = this.datos.decimales_moneda
            this.fecha = '',
            this.comentarioPago = '',
            this.$refs.modalRegistrarPago.toggle();
        },
        async pagar(){
            try {
                if(this.valorPagar == 0) return this.notificacion('Advertencia', 'El valor debe ser mayor a cero', 'warning')
                if(this.valorPagar > this.valorMaximo) return this.notificacion('Advertencia', `El valor maximo a pagar es de ${this.valorMaximo}`, 'warning')
                if(!this.fecha || this.fecha == '') return this.notificacion('Advertencia', `Por favor seleccione la fecha del pago`, 'warning')

                const { image: foto } = this.$refs.fotoTransferencia.instanciaCrop.dataBase64.output

                const form = {
                    id_pedido: this.datos.id_pedido,
                    valor: this.valorPagar,
                    comentario: this.comentarioPago,
                    fecha: this.fecha,
                    foto
                }

                const { data } = await pagos.registarPagoCliente(form)
                if(data.exito){
                    this.$emit('saved')
                    this.$refs.modalRegistrarPago.toggle();
                }

            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
